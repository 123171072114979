<template>
  <div class="content content_in">
    <div class="title-row row justify-content-between">
      <div class="col-12 col-md-auto">
        <h1>{{ pageTitle }}</h1>
      </div>
      <div class="col-12 col-xl-auto flex-grow-1 max-770">
        <div class="row align-items-center postback-wrapper">
          <div class="form-item pb-2">
            <div class="form-item__field">
              <div class="search-field">
                <input v-model.trim="search" type="search" @keydown.enter="getPostbacks" :placeholder="$t('platforms.search_by_link')" />
                <button @click="getPostbacks" class="icon-search" title="Search campaigns postback">
                  <svg v-svg symbol="search" size="0 0 15 16"></svg>
                </button>
              </div>
            </div>
          </div>
          <div class="form-item pb-2">
            <div class="form-item__field">
              <button @click="$router.push('/platform-postback')" :disabled="$route.name == 'PlatformSettings'" class="btn">{{ $t("platforms.postback.look_all") }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="c-table mt-5 mt-md-0">
      <div class="inner">
        <vue-good-table styleClass="table  _no-wrap" :columns="columns" :rows="rows">
          <template slot="table-column" slot-scope="props">
            <span @click="toSort(props.column.field)" class="cursor-pointer"> {{ $t(props.column.label) }}: </span>
          </template>
          <template slot="table-row" slot-scope="props">
            <template v-if="props.column.field === 'id'">
              {{ props.row.id }}
            </template>
            <template v-else-if="props.column.field === 'date'"> {{ props.row.request_sent_at }} </template>
            <template v-else-if="props.column.field === 'link'">
              <span class="postback-url">
                <span class="icon-link">
                  <svg v-svg symbol="link" size="0 0 17 17"></svg>
                </span>
                {{ props.row.url }}
              </span>
            </template>
            <template v-else-if="props.column.field === 'status'">
              <span
                v-tooltip="{
                  content: postbackDetails(props.row),
                  offset: '10',
                  html: true,
                  triggers: ['hover', 'click'],
                }"
                class="postback-status"
                :class="props.row.status_code == 200 ? 'success' : 'error'"
              >
                <template v-if="props.row.status_code == 200">
                  <svg width="9" height="7" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.22136 7C3.04127 7 2.87019 6.92187 2.74412 6.78514L0.195848 4.02128C-0.0652826 3.73805 -0.0652826 3.26927 0.195848 2.98605C0.456978 2.70283 0.889194 2.70283 1.15033 2.98605L3.22136 5.2323L7.84968 0.212417C8.11081 -0.0708057 8.54302 -0.0708057 8.80415 0.212417C9.06528 0.49564 9.06528 0.964422 8.80415 1.24765L3.6986 6.78514C3.57254 6.92187 3.40145 7 3.22136 7Z" />
                  </svg>
                </template>
                <template v-else>
                  <span> ! </span>
                </template>
              </span>
            </template>
          </template>
          <div class="d-flex justify-content-center" slot="emptystate">
            <template v-if="!loadDataTable">
              {{ $t("any_results") }}
            </template>
            <template v-else>
              <div class="d-flex justify-content-center">
                <img :src="require('@/assets/images/rolling.svg')" alt="loader" class="loader" />
              </div>
            </template>
          </div>
        </vue-good-table>
      </div>
    </div>
    <div class="sep"></div>
    <div class="c-pagination">
      <ul class="pagination" v-if="rows && rows.length && rows.length > 0">
        <li class="page-item">
          <button @click.prevent="page--" :class="{ disabled: page <= 1 }" :disabled="page <= 1" class="page-link icon-prev prev" data-ci-pagination-page="2" rel="prev" :title="$t('statistic.prev')">
            <svg v-svg symbol="prev" size="0 0 8 14"></svg>
          </button>
        </li>
        <li class="page-item">
          <button @click.prevent="page++" :class="{ disabled: page * limit >= total }" :disabled="page * limit >= total" class="page-link icon-next next" data-ci-pagination-page="2" rel="next" :title="$t('statistic.next')">
            <svg v-svg symbol="next" size="0 0 8 14"></svg>
          </button>
        </li>
        <li class="page-item">{{ page === 1 ? page : limit * (page - 1) + 1 }} - {{ limit * (page - 1) + rows.length }} {{ $t("from") }} {{ total }}</li>
        <li>
          <custom-multiselect class="ml-2" v-model="limit" :list="optionsPagination" :allowEmpty="false">
            <template v-slot:block="props">
              <span>
                {{ props.value }}
              </span>
            </template>
            <template v-slot:list="props">
              <span>
                {{ props.value }}
              </span>
            </template>
          </custom-multiselect>
        </li>
      </ul>
    </div>
    <alert v-if="$alert.title" />
  </div>
</template>
<script>
import servicePostback from "@/api/servicePostback";
import "@/assets/css/_pagination.scss";

export default {
  name: "Postbacks",
  data() {
    return {
      isLocal: null,
      search: "",
      loadDataTable: false,
      columns: [
        {
          label: "",
          field: "id",
          sortable: false,
        },
        {
          label: this.$t("platforms.postback.table.date"),
          field: "date",
          sortable: false,
        },
        {
          label: this.$t("platforms.postback.table.link"),
          field: "link",
          sortable: false,
        },
        {
          label: this.$t("platforms.postback.table.status"),
          field: "status",
          sortable: false,
        },
      ],
      rows: [],

      //pagination
      optionsPagination: [10, 25, 50, 100],
      total: null,
      page: 1,
      offset: null,
      limit: 10,
    };
  },
  watch: {
    $route: function () {
      this.$router.go();
      this.isLocal = Boolean(this.$router.params?.id);
      const params = this.$route.query;
      this.offset = params.offset || 0;
      if (this.offset === 0) {
        this.page = 1;
      } else {
        this.page = this.offset / this.limit + 1;
      }
    },
    page: function () {
      if (this.page === 1 || this.page < 0) {
        this.offset = null;
        this.$router
          .push({
            path: `/platform-postback${this.$route.params?.id ? "/" + this.$route.params?.id : ""}`,
          })
          .catch(() => {});
      } else {
        this.offset = this.limit * (this.page - 1);
        this.$router
          .push({
            path: `/platform-postback${this.$route.params?.id ? "/" + this.$route.params?.id : ""}`,
            query: { offset: this.offset },
          })
          .catch((error) => {
            this.$alert({
              title: this.$t("sent_error"),
              text: error,
            });
          });
      }
    },
    limit: {
      immediate: false,
      handler() {
        this.page = 1;
        localStorage.setItem("paginationLimit", this.limit);
        this.getPostbacks();
      },
    },
    offset: {
      immediate: false,
      handler() {
        this.getPostbacks();
      },
    },
  },
  created() {
    this.isLocal = Boolean(this.$route.params?.id);
    this.columns[0].label = this.isLocal ? "ID" : "Source ID";
    const savedLimit = localStorage.getItem("paginationLimit");
    if (savedLimit && savedLimit != this.limit) {
      this.limit = Number(savedLimit);
    } else {
      this.getPostbacks();
    }
  },
  methods: {
    postbackDetails(data) {
      return `
        <span class='postback-desc'>
          <span class='postback-desc__block'>
            <span class='postback-desc__title'>${this.$t("platforms.postback.table.status")}:</span>  <span class='postback-desc__value ${data.status_code == 200 ? "success" : "error"}'>${data.status_code}</span>
          </span>
          <span class='postback-desc__block'>
            <span class='postback-desc__title'>${this.$t("platforms.postback.response_time")}:</span>  <span class='postback-desc__value'>${data.response_time} ${this.timeValue}</span>
          </span>
          <span class='postback-desc__block'>
            <span class='postback-desc__title'>${this.$t("platforms.postback.description")}:</span>  <span class='postback-desc__value'>${data.description}</span>
          </span>
        </span>
        `;
    },
    getPostbacks() {
      const payload = {
        url: this.search || undefined,
      };
      this.loadDataTable = true;
      try {
        if (this.isLocal) {
          servicePostback
            .getPlatformsPostback(payload, this.$route.params.id)
            .then((res) => {
              if (res && res.status == 200) {
                this.total = res.data.length;
                this.rows = res.data;
              }
            })
            .finally(() => {
              this.loadDataTable = false;
            });
        } else {
          servicePostback
            .getPostback(payload)
            .then((res) => {
              if (res && res.status == 200) {
                this.total = res.data.length;
                this.rows = res.data;
              }
            })
            .finally(() => {
              this.loadDataTable = false;
            });
        }
      } catch (error) {
        this.$alert({
          title: this.$t("sent_error"),
          text: error,
        });
      }
    },
  },
  computed: {
    pageTitle() {
      return this.isLocal ? this.$t("platforms.postback.local_title") : this.$t("platforms.postback.global_title");
    },
    timeValue() {
      return this.$i18n.locale === "ru" || this.$i18n.locale === "uk" ? "мс" : "ms";
    },
  },
};
</script>
<style lang="scss">
.postback-url {
  color: #317475;
  svg {
    fill: #317475;
  }
}

.postback-status {
  position: relative;
  width: 21px;
  height: 21px;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #262627;
  border-radius: 50%;
  transition: 0.25s all ease;
  cursor: pointer;

  svg {
    fill: #262627;
  }

  &.error {
    border-color: #c62424;
    color: #c62424;

    &:focus,
    &:hover {
      background: #c62424;
      color: white;
    }
  }

  &.success {
    border-color: #37807d;
    svg {
      fill: #37807d;
    }
    &:focus,
    &:hover {
      background: #37807d;
      svg {
        fill: white;
      }
    }
  }
}

.postback-wrapper {
  gap: 0 16px;
  margin: 0;
  .form-item:first-child {
    flex: 1;
  }
}

.postback-desc {
  display: flex;
  flex-direction: column;
  background: white;

  &__block {
    display: flex;
    width: 100%;
    gap: 0 6px;
  }

  &__title {
    font-weight: 400;
    color: #9e9e9e;
  }

  &__value {
    font-weight: 500;
    color: #141414;

    &.error {
      color: #c62424;
    }

    &.success {
      color: #37807d;
    }
  }
}
</style>
